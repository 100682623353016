(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-choice-button/assets/javascripts/coupon-choice-button.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-choice-button/assets/javascripts/coupon-choice-button.js');
"use strict";

const {
  ReactButton,
  ReactIcon
} = svs.ui;
const {
  analytics
} = svs.components;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_AM
} = svs.components.lbUtils.useMediaQuery;
const {
  useBranding
} = svs.components.tipsen.hooks;
const CouponChoiceButton = _ref => {
  let {
    children,
    className,
    'data-testid': dataTestId,
    icon,
    isDisabled,
    isSelected,
    onClick,
    ariaLabel
  } = _ref;
  const classNames = ['btn-coupon-choice'];
  isSelected && classNames.push('btn-coupon-choice-selected');
  isDisabled && classNames.push('btn-coupon-choice-disabled');
  className && classNames.push(className);
  const isSmall = useMediaQuery(breakpoints.down(BREAKPOINT_AM));
  const buttonAndIconSize = isSmall ? 200 : 300;
  const [productBranding] = useBranding();
  productBranding && classNames.push("btn-coupon-choice-branding-".concat(productBranding));
  const trackEventOnCouponChoiceButton = () => {
    analytics.trackEvent({
      category: "Nya tipsen",
      action: children,
      opt_label: location.pathname
    });
  };
  return React.createElement(ReactButton, {
    "aria-label": ariaLabel,
    className: classNames.join(' '),
    "data-testid": dataTestId,
    disabled: isDisabled,
    "icon-position": "left",
    onClick: e => {
      trackEventOnCouponChoiceButton();
      onClick && onClick(e);
    },
    size: buttonAndIconSize
  }, icon && React.createElement(ReactIcon, {
    icon: icon,
    size: buttonAndIconSize
  }), children && React.createElement("span", {
    className: "".concat(icon ? 'btn-text' : '')
  }, children));
};
setGlobal('svs.components.tipsen.couponChoiceButton.CouponChoiceButton', CouponChoiceButton);

 })(window);